import React from 'react';
import BookTeaser from '../../BookTeaser';
import PageHeader from '../../PageHeader';
import QuoteImage from '../../QuoteImage';
import data from '../../../data/masterpieceMindsetBook';

import styles from '../books.module.scss';

const MasterpieceMindsetBook = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108735/therapistFullBackgroung_lapqmb.jpg';
  const quoteImage =
    'https://res.cloudinary.com/dewd4pral/image/upload/v1673486507/mindset_book_quote_img.jpg';

  return (
    <main>
      <PageHeader
        pageName="The Masterpiece Mindset"
        pageCategory="Books"
        headerImage={background}
      />
      <div className={styles.buyBookWrapperTop}>
        <BookTeaser
          bookName="The Masterpiece Mindset"
          shouldLinkToRoute={false}
          shouldShowPreview
        />
      </div>
      <div className={styles.textContainer}>
        <h2 className={styles.header}>
          The Masterpiece Mindset: Empowering Your Kids to Be Confident, Kind, and Resilient
        </h2>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.summary1 }} />
      </div>

      <QuoteImage image={quoteImage} maxWidth={550} quote={data.imageQuote} />

      <div className={styles.textContainer}>
        <p className={styles.copy} dangerouslySetInnerHTML={{ __html: data.summary2 }} />
      </div>
      <div className={styles.buyBookWrapperBottom}>
        <BookTeaser
          bookName="The Masterpiece Mindset"
          shouldLinkToRoute={false}
          shouldShowPreview
        />
      </div>
    </main>
  );
};

export default MasterpieceMindsetBook;
