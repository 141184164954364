import React from 'react';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import MasterpieceMindset from '../../components/Books/MasterpieceMindset';

export default props => (
  <>
    <SEO
      title="The Masterpiece Mindset | Mindset Family Therapy"
      description="While there's no one manual with all the answers for raising children, there is a roadmap: The Masterpiece Mindset offers parents a step-by-step strategy to help their children become who they are capable of becoming."
      pathname="/books/the-masterpiece-mindset"
    />
    <Layout>
      <MasterpieceMindset {...props} />
    </Layout>
  </>
);
