const masterpieceMindsetBook = {
  imageQuote:
    '<p>Your family culture will trump anything else you ever do as a parent. Be intentional about being&nbsp;intentional."</p><p>DAVE HAGEN</p>',
  summary1:
    'Discover a parenting plan to help your children sculpt a masterpiece of their lives. Are you ready to take your kids and family to the next level? You have come to the right place! Bring out the best in yourself as a parent so you can EMPOWER YOUR CHILDREN to bring out the best in themselves!<br><br>What is the number-one critical life skill your children need to realize their potential? How can you help your children become less anxious or depressed and more resilient? How can you create a family culture that transforms your children and future generations of your family? What can you do to create an empowering core identity in your children? What is the greatest inheritance you can leave your children?',
  summary2:
    "While there's no one manual with all the answers for raising children, there is a roadmap: <i>The Masterpiece Mindset</i> offers parents a step-by-step strategy to help their children become who they are capable of becoming.<br><br>The answers to these questions and much more are found within these pages. The Hagens take the invaluable insights they've gleaned from both practical parenting and professional experience and give you the specific tools you need to become a more intentional parent.",
};

export default masterpieceMindsetBook;
